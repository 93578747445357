<template>
  <v-list-item class="pa-4 foot" @click.native.stop>
    <!-- <v-row class="pa-4"> -->
    <v-avatar color="#D8F8FF" size="44">
      <v-icon color="#0095B8" size="32"> mdi-account </v-icon></v-avatar
    >
    <div class="white--text pl-3">
      <p class="text-no-wrap mb-1">victoria.wang/王意雲</p>
      <div class="d-flex actions">
        <span class="pr-4 pointer">帳號設定</span>
        <span class="pointer">登出</span>
      </div>
    </div>
    <v-btn
      class="text-none mb-4"
      color="white"
      href="https://vuetifyjs.com"
      small
      text
    >
    </v-btn>
    <!-- </v-row> -->
  </v-list-item>
</template>

<script>
// import { sync } from "vuex-pathify";
export default {
  name: "DefaultDrawerHeader",
  computed: {
    // mini: sync("app/mini"),
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/main.scss";
.foot {
  margin-bottom: 0 !important;
  box-shadow: 0 -2px 5px rgba(#000, 0.1);
}
</style>
